import React, { createContext, useState, useEffect } from 'react';
import { PostData } from './utils/PostData';

const DataContext = createContext();

const DataProvider = ({ children }) => {
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const url = "http://hammerheadhomeservice.com";

    useEffect(() => {
        const fetchServices = async () => {
            // Check if services are stored in local storage
            const storedServices = localStorage.getItem('services');
            const storedTimestamp = localStorage.getItem('servicesTimestamp');
            const currentTime = new Date().getTime();

            // If services exist and are not older than 24 hours, use them
            if (storedServices && storedTimestamp && currentTime - storedTimestamp < 24 * 60 * 60 * 1000) {
                setServices(JSON.parse(storedServices));
                setLoading(false);
            } else {
                // Otherwise, fetch from the server
                try {
                    const response = await PostData('get-services.php', {}); // Assuming 'getServices' is your endpoint
                    if (response.success) {
                        setServices(response.services);
                        localStorage.setItem('services', JSON.stringify(response.services));
                        localStorage.setItem('servicesTimestamp', currentTime.toString());
                    } else {
                        console.error('Failed to fetch services');
                    }
                } catch (error) {
                    console.error('Error fetching services:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchServices();
    }, []);

    return (
        <DataContext.Provider value={{ url, services, loading }}>
            {children}
        </DataContext.Provider>
    );
};

export { DataContext, DataProvider };