import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Image from '../helpers/Image';
import FadeInSection from "../helpers/FadeInSection";
import { DataContext } from '../DataProvider';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Home() {
    const { url, services, loading } = useContext(DataContext);

    return (
        <main id="home">
            <section className="hero-container">
                <Image src={`${url}/assets/images/hero-img.jpeg`} alt="Power Washer" className="hero-img" />
                <div className='floating-logo'>
                    <Image src={`${url}/assets/images/testshark.png`} alt="Hammer Head Home Services" className="hammer-head-hero-logo" />
                    <div className='hammer-head'>HAMMERHEAD</div>
                    <div className='hero-home-services'>HOME SERVICES</div>
                    <div className="free-estimates">
                        Free Estimates <br />
                        Special Pricing For Seniors
                    </div>
                    <div className='hero-quote'>
                        "Quote"
                    </div>
                    <Link to="/quote" className="button button-blue" type="button">Get Quote</Link>
                    <div className='hero-quote'>
                        Safe - Dependable - Fully Insured
                    </div>
                </div>
            </section>

            <section className='home-services' id="services">
                <h1 className="text-center">At Home Services</h1>
                <hr />
                <Row>
                    {loading
                        ? <h1>Loading...</h1>
                        : services.map((service, index) => {
                            return (
                                <Col className="service" md={2} key={index}>
                                    <h3>
                                        <a className="underline-animation" href={`#service-${service.id}`}>
                                            {service.name}
                                        </a>
                                    </h3>
                                </Col>
                            )
                        })}
                </Row>
            </section>

            <section>
                {loading
                    ? <h1>Loading...</h1>
                    : services.map((service, index) => {
                        if (index % 2 === 0) {
                            return (
                                <FadeInSection key={index}>
                                    <div className="display-services" id={`service-${service.id}`}>
                                        <div className='service-img-container'>
                                            <Image src={`${url}/assets/images/${service.image}`} alt={service.name} className="service-image" />
                                        </div>
                                        <div className="service-description">
                                            <h1>{service.name}</h1>
                                            <p>
                                                {service.description}
                                            </p>
                                            <Link to={`/service/${service.id}`} className="button button-blue">Read More</Link>
                                        </div>
                                    </div>
                                </FadeInSection>
                            )
                        } else {
                            return (
                                <FadeInSection key={index}>
                                    <div className="display-services display-services-odd" id={`service-${service.id}`}>
                                        <div className='service-img-container'>
                                            <Image src={`${url}/assets/images/${service.image}`} alt={service.name} className="service-image" />
                                        </div>
                                        <div className="service-description">
                                            <h1>{service.name}</h1>
                                            <p>
                                                {service.description}
                                            </p>
                                            <Link to={`/service/${service.id}`} className="button button-white">Read More</Link>
                                        </div>
                                    </div>
                                </FadeInSection>
                            )
                        }
                    })}
            </section>
            <FadeInSection>
                <section className='home-quote-container'>
                    <div className='quote-image-container'>
                        {/* <Image src={`${url}/assets/images/black-shark.png`} alt="black shark logo" className="home-quote-logo left-logo" /> */}
                    </div>
                    <div className="home-quote">
                        <h1>
                            <i>"Quote About The Business"</i>
                        </h1>
                    </div>
                    <div className='quote-image-container'>
                        {/* <Image src={`${url}/assets/images/black-shark.png`} alt="black shark logo" className="home-quote-logo" /> */}
                    </div>
                </section>
            </FadeInSection>
        </main>
    )
}

export default Home;
