import React from 'react';

function QuoteSuccess() {
    return (
        <>
            <h1>You Have Sent Us Your Quote Request!</h1>
            <h3>We Well Get Back To You In 2 Business Days</h3>
        </>
    )
}

export default QuoteSuccess;