import React, { useRef, useState, useContext } from 'react';
import { DataContext } from '../DataProvider';
import { useParams, useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { SendQuote } from '../utils/SendQuote';
import "../assets/css/quote.css";

function Quote() {
    const formRef = useRef();
    const navigate = useNavigate();
    const serviceId = useParams().id;
    const [error, setError] = useState(null);
    const { services, loading } = useContext(DataContext);

    return (
        <main className="quote">
            <section className="quote-container">
                <h1>Get a Quote</h1>
                <form className="quote-form" ref={formRef}>
                    <div className="form-input">
                        {error && <Alert variant="danger" className='text-center'>{error}</Alert>}
                        <label className='quote-label' htmlFor="name">Name<span className='red'>*</span></label>
                        <input className="quote-input" type="text" id="name" name="name" required placeholder='Name' />
                    </div>
                    <div className="form-input">
                        <label className='quote-label' htmlFor="email">Email<span className='red'>*</span></label>
                        <input className="quote-input" type="email" id="email" name="email" required placeholder='example@email.com' />
                    </div>
                    <div className="form-input">
                        <label className='quote-label' htmlFor="phone">Phone<span className='red'>*</span></label>
                        <input className="quote-input" type="tel" id="phone" name="phone" required placeholder='Phone Number' />
                    </div>
                    <div className="form-input">
                        <label className='quote-label' htmlFor="address">Address (optional)</label>
                        <input className="quote-input" type="tel" id="address" name="address" placeholder='Address' />
                    </div>
                    <div className="form-input d-flex flex-row gap-2">
                        <div className='d-flex flex-column w-50'>
                            <label className='quote-label' htmlFor="city">City</label>
                            <input className="quote-input" type="tel" id="city" name="city" placeholder='City' />
                        </div>
                        <div className='d-flex flex-column w-50'>
                            <label className='quote-label' htmlFor="postal-code">Postal Code</label>
                            <input className="quote-input" type="tel" id="postal-code" name="postal-code" placeholder='Postal Code' />
                        </div>
                    </div>
                    <div className="form-input align-items-start">
                        <label className='quote-label' htmlFor="service">Service<span className='red'>*</span></label>
                        {loading
                            ? <h1>Loading...</h1>
                            : services.map((service, index) => (
                                <div key={index}>
                                    <input id={service.id} type="checkbox" value={service.name} key={service.id} style={{ margin: "10px" }} defaultChecked={serviceId === service.id} />
                                    <label htmlFor={service.id}>{service.name}</label>
                                </div>
                            ))}
                    </div>
                    <div className="form-input">
                        <label className='quote-label' htmlFor="message">Message<span className='red'>*</span></label>
                        <textarea id="message" name="message" rows={8} required placeholder='Please Detail Your Property As Best As You Can'></textarea>
                    </div>
                    <center>
                        <button className="button button-blue" type="button" onClick={(e) => SendQuote(e, formRef, setError, navigate)}>Request Quote</button>
                    </center>
                </form>
            </section>
        </main>
    )
}

export default Quote;